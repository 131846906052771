import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const WidgetDocumentSearch = React.lazy(() =>
    import('../WidgetDocumentSearch')
);
const widgetDocumentSearchRoutesRegistry = [
    {
        path: PAGE_URL.WIDGET_DOCUMENT_SEARCH_PAGE,
        element: <WidgetDocumentSearch />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default widgetDocumentSearchRoutesRegistry;

import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const globalConfigMenuRegistry = {
    core_globalConfig: {
        label: "Global Config",
        link: PAGE_URL.GLOBAL_CONFIG_PAGE,
        key: PAGE_URL.GLOBAL_CONFIG_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default globalConfigMenuRegistry;

import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const GlobalConfig = React.lazy(() =>
    import('../GlobalConfig')
);
const globalConfigRoutesRegistry = [
    {
        path: PAGE_URL.GLOBAL_CONFIG_PAGE,
        element: <GlobalConfig />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default globalConfigRoutesRegistry;

import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const widgetDocumentSearchMenuRegistry = {
    search_widgetDocumentSearch: {
        label: "Widget Document Search",
        link: PAGE_URL.WIDGET_DOCUMENT_SEARCH_PAGE,
        key: PAGE_URL.WIDGET_DOCUMENT_SEARCH_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default widgetDocumentSearchMenuRegistry;
